import { Howl } from "howler";
import React, { createContext, FC, useState } from "react";

const AudioContext = createContext({ audioEnabled: true, toggleAudio: () => { } });

const AudioContextProvider: FC<{ children: JSX.Element }> = ({ children }) => {
    const [audioEnabled, setAudioEnabled] = useState(true)

    const toggleAudio = () => {
        setAudioEnabled(!audioEnabled)
    }

    return (
        <div>
            <AudioContext.Provider value={{ audioEnabled, toggleAudio }}>
                {children}
            </AudioContext.Provider>
        </div>
    )
}


export { AudioContext, AudioContextProvider }