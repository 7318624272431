import '../styles/globals.css'
import App, { AppContext } from 'next/app';
import { Type as FooterType } from '../components/globals/Footer'
import { Type as LoadingScreenType } from '../components/globals/Loading'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head';
import { AudioContextProvider } from '../components/providers/AudioContext';
import Script from 'next/script';

type AppProps = {
  pageProps: any
  Component: React.FC<{ footer: FooterType, loadingScreen: LoadingScreenType }>
} & {
  loadingScreen: LoadingScreenType
  footer: FooterType
};


function MyApp(appProps: AppProps) {

  const { Component, pageProps, loadingScreen, footer } = appProps;


  let darkThemeMq = { matches: false };

  if (typeof window !== 'undefined')
    darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");


  return (
    <><Head>
      {!darkThemeMq.matches && (
        <>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32.png?v=M4KN2GElyG" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16.png?v=M4KN2GElyG" />
          <link rel="shortcut icon" href="/favicons/favicon.ico?v=M4KN2GElyG" />
        </>
      )}
      {darkThemeMq.matches && (
        <>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-black-32x32.png?v=M4KN2GElyG" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-black-16x16.png?v=M4KN2GElyG" />
          <link rel="shortcut icon" href="/favicons/favicon-black.ico?v=M4KN2GElyG" />
        </>
      )}
    </Head>
      <AudioContextProvider>

        <Component {...pageProps} footer={footer} loadingScreen={loadingScreen} />
      </AudioContextProvider>
    </>
  )
}


MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  const [footer, loadingScreen] = await Promise.all([
    fetch(`${process.env.CMS_HOST}/api/globals/footer`).then((res) => res.json()),
    fetch(`${process.env.CMS_HOST}/api/globals/loadingScreen`).then((res) => res.json()),
  ]);

  return {
    ...appProps,
    footer,
    loadingScreen,
  };
};


export default MyApp